import React from "react";
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero
        smaller
        subtitle="This page doesn't seem to exist. Click a link in the menu or footer to get back on track"
      >
        YOU SEEM TO BE
        <br />
        LOST IN SPACE
      </Hero>
    </Layout>
  );
};

export default NotFoundPage;
